<template>
  <a-drawer
    title="经纪人详情"
    placement="right"
    :width="drawerWidth"
    class="myDescription"
    :closable="false"
    :visible="visible"
    :after-visible-change="afterVisibleChange"
    @close="handleCancel"
  >
    <a-card :bordered="false" :loading="loading">
      <a-descriptions title="基本信息">
        <a-descriptions-item label="姓名" span="3">{{ model ? model.name : '暂无信息' }}</a-descriptions-item>
        <a-descriptions-item label="昵称" span="3">{{ model ? model.wx.nickName : '暂无信息' }}</a-descriptions-item>
        <a-descriptions-item label="手机号" span="3">{{ model ? model.phone : '暂无信息' }}</a-descriptions-item>
        <a-descriptions-item label="邀请码" span="3">{{ model ? model.inviteCode : '暂无信息' }}</a-descriptions-item>
        <a-descriptions-item label="注册时间" span="3">{{ model ? model.createTime : '暂无信息' }}</a-descriptions-item>
        <a-descriptions-item label="地址" span="3">{{ model ? `${model.wx.country ? `${model.wx.country}-` : ''}${model.wx.province ? `${model.wx.province}-` : ''}${ model.wx.city ? model.wx.city : '-' }` : '暂无信息' }}</a-descriptions-item>
      </a-descriptions>
      <a-divider style="margin-bottom: 32px" />
      <a-descriptions title="实名信息">
        <a-descriptions-item label="真实姓名" span="3">{{ '暂无信息' }}</a-descriptions-item>
        <a-descriptions-item label="身份证号" span="3">{{ '暂无信息' }}</a-descriptions-item>
        <a-descriptions-item label="出生日期" span="3">{{ '暂无信息' }}</a-descriptions-item>
        <a-descriptions-item label="籍贯" span="3">{{ '暂无信息' }}</a-descriptions-item>
        <a-descriptions-item label="民族" span="3">{{ '暂无信息' }}</a-descriptions-item>
        <a-descriptions-item label="学历" span="3">{{ '暂无信息' }}</a-descriptions-item>
      </a-descriptions>
      <a-divider style="margin-bottom: 32px" />
      <!-- <a-descriptions title="佣金信息"> </a-descriptions>
        <a-table :columns="commissionColumns" :data-source="commission" :pagination="false" bordered> </a-table> -->
    </a-card>
  </a-drawer>
</template>

<script>
import { brokerGet } from '@/api/broker'
import { commissionColumns } from '../js/broker'
export default {
  data() {
    return {
      commissionColumns,
      id: null,
      noticeTitle: '经纪人详情',
      visible: false,
      loading: false,
      model: null
    }
  },
  methods: {
    showModal(record) {
      console.log(record, 'record');
      this.visible = true
      this.id = record.id
      this.model = record
      // this.fetchModel()
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.model = null
    },
    afterVisibleChange(val) {
      console.log('visible', val)
    },
    fetchModel() {
      this.loading = true
      brokerGet(this.id)
        .then(res => {
          if (res.id) {
            console.log(res, 'agent res')
            this.model = res
          }
        })
        .finally(() => {
          console.log('2222');
          this.loading = false
        })
    }
  }
}
</script>
