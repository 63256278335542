<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-tabs type="card" @change="tabChange">
        <template v-for="tab in tabs">
          <a-tab-pane :tab="tab.name" :key="tab.id"></a-tab-pane>
        </template>
      </a-tabs>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="搜索">
                <a-input v-model="queryParam.keyword" placeholder="请输入搜索信息" />
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="findByKeyword">查询</a-button>
                <a-button style="margin-left: 8px" @click="tableReset">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data="brokerData"
        :alert="true"
        :totalCount="totalCount"
        showPagination="auto"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="inviteType" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="lookInfo(record)">查看</a>
            <a-divider type="vertical" />
            <a @click="handleEdit(record)">编辑</a>
          </template>
        </span>
      </s-table>

      <create-form
        ref="createModal"
        :visible="visible"
        :loading="confirmLoading"
        :model="mdl"
        @cancel="handleCancel"
        @ok="handleOk"
      />
      <broker-drawer ref="brokerDrawer"></broker-drawer>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import moment from 'moment'
import { STable, Ellipsis } from '@/components'
import { addBrokers, editBrokers, queryBrokers } from '@/api/broker'
import { tabs, commission, commissionColumns, statusMap, finishColumns, unfinishColumns } from './js/broker'
import CreateForm from './modules/CreateForm'
import brokerDrawer from './modules/BrokerDrawer'

export default {
  name: 'BrokerList',
  components: {
    STable,
    Ellipsis,
    CreateForm,
    brokerDrawer
  },
  data() {
    return {
      totalCount: 0,
      enterStatus: true,
      columns: finishColumns,
      commission,
      commissionColumns,
      statusMap,
      finishColumns,
      unfinishColumns,
      tabs,
      visible: false,
      confirmLoading: false,
      mdl: null,
      advanced: false,
      queryParam: {},
      search: {
        visible: false
      },
      brokerData: parameter => {
        const param = {
          enterStatus: this.enterStatus
        }
        const params = this.paramFormat(parameter, param, 'enterTime')
        Object.assign(params, this.queryParam)
        return queryBrokers(params).then(res => {
          if (res.success) {
            const result = this.resFormat(res)
            this.totalCount = result.totalCount
            return result
          }
        })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // broker info
      pStyle: {
        fontSize: '16px',
        color: 'rgba(0,0,0,0.85)',
        lineHeight: '24px',
        display: 'block',
        marginBottom: '16px'
      },
      pStyle2: {
        marginBottom: '24px'
      }
    }
  },
  filters: {
    statusFilter(type) {
      if (!type) {
        type = 0
      }
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      if (!type) {
        type = 0
      }
      return statusMap[type].status
    }
  },
  created() {},
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  methods: {
    tabChange(key) {
      this.enterStatus = key === 'broker_finish'
      if (this.enterStatus) {
        this.columns = this.finishColumns
      } else {
        this.columns = this.unfinishColumns
      }
      this.reload()
    },
    reload() {
      this.queryParam = {}
      this.tableHome()
      this.$refs.table.refresh()
    },
    handleAdd() {
      this.mdl = null
      this.visible = true
    },
    handleEdit(record) {
      this.visible = true
      this.mdl = { ...record }
    },
    lookInfo(record) {
      this.$refs.brokerDrawer.showModal(record)
    },
    handleOk() {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          const requestParameters = { ...values }
          if (this.mdl !== null) {
            requestParameters.id = this.mdl.id
            // 新增/修改
            editBrokers(requestParameters)
              .then(res => {
                setTimeout(() => {
                  const msg = '编辑成功'
                  this.visible = false
                  this.confirmLoading = false
                  // 重置表单数据
                  form.resetFields()
                  // 刷新表格
                  this.$refs.table.refresh()
                  this.$message.success(msg)
                }, 1000)
              })
              .catch(() => {
                this.confirmLoading = false
              })
          } else {
            // 新增/修改
            addBrokers(requestParameters)
              .then(res => {
                setTimeout(() => {
                  const msg = '新建成功'
                  this.visible = false
                  this.confirmLoading = false
                  // 重置表单数据
                  form.resetFields()
                  // 刷新表格
                  this.$refs.table.refresh()
                  this.$message.success(msg)
                }, 1000)
              })
              .catch(() => {
                this.confirmLoading = false
              })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    findByKeyword() {
      this.$refs.table.refresh()
    },
    tableReset() {
      this.queryParam = {}
      this.tableHome()
      this.$refs.table.refresh()
    }
  }
}
</script>
