export const tabs = [
    {
        id: 'broker_finish',
        name: '已入驻',
        status: true
    },
    {
        id: 'broker_unfinish',
        name: '未入驻',
        status: false
    }
]

export const commission = [
    {
        money: '￥300,000.00',
        clsd: '￥200,000.00',
        wait: '￥100,000.00',
        yes: '￥50,000.00',
        approve: '￥50,000.00'
    }
]

export const commissionColumns = [
    {
        title: '佣金总额',
        dataIndex: 'money'
    },
    {
        title: '已结佣金',
        dataIndex: 'clsd'
    },
    {
        title: '待结佣金',
        dataIndex: 'wait'
    },
    {
        title: '可结佣金',
        dataIndex: 'yes'
    },
    {
        title: '审批中',
        dataIndex: 'approve'
    }
]

export const statusMap = {
    1: {
        status: 'success',
        text: '邀请码'
    },
    2: {
        status: 'success',
        text: '邀请链接'
    },
    3: {
        status: 'success',
        text: '二维码邀请'
    },
    4: {
        status: 'success',
        text: '二维码邀请（经纪人邀请）'
    },
    5: {
        status: 'success',
        text: '二维码邀请（门店店长邀请）'
    },
    0: {
        status: 'success',
        text: '其他'
    },
    null: {
        status: 'success',
        text: '其他'
    },
    'undefined': {
        status: 'success',
        text: '其他'
    }
}

export const finishColumns = [
    {
        title: '姓名',
        dataIndex: 'name'
    },
    {
        title: '昵称',
        dataIndex: 'username'
    },
    {
        title: '电话',
        dataIndex: 'phone'
    },
    {
        title: '邀请方式',
        dataIndex: 'inviteType',
        scopedSlots: { customRender: 'inviteType' }
    },
    {
        title: '邀请人',
        dataIndex: 'residentName',
        scopedSlots: { customRender: 'residentName' }
    },
    {
        title: '入驻时间',
        dataIndex: 'enterTime',
        sorter: true
    },
    {
        title: '所属门店',
        dataIndex: 'shopName'
    },
    {
        title: '备注',
        dataIndex: 'remark',
        scopedSlots: { customRender: 'remark' }
    },
    {
        title: '操作',
        dataIndex: 'action',
        width: '150px',
        scopedSlots: { customRender: 'action' }
    }
]

export const unfinishColumns = [
    {
        title: '姓名',
        dataIndex: 'name'
    },
    {
        title: '昵称',
        dataIndex: 'username'
    },
    {
        title: '电话',
        dataIndex: 'phone'
    },
    {
        title: '邀请方式',
        dataIndex: 'inviteType',
        scopedSlots: { customRender: 'inviteType' }
    },
    {
        title: '邀请人',
        dataIndex: 'residentName',
        scopedSlots: { customRender: 'residentName' }
    },
    {
        title: '备注',
        dataIndex: 'remark',
        scopedSlots: { customRender: 'remark' }
    },
    {
        title: '操作',
        dataIndex: 'action',
        width: '150px',
        scopedSlots: { customRender: 'action' }
    }
]
