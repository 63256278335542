<template>
  <a-modal
    title="经纪人"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="经纪人姓名">
          <a-input v-decorator="['username', {rules: [{required: true, message: '请输入经纪人姓名'} ,{ max: 15, message: '长度不能大于15'}]}]" />
        </a-form-item>
        <a-form-item label="经纪人昵称">
          <a-input v-decorator="['name', {rules: [{required: true, message: '请输入经纪人昵称'} ,{ max: 15, message: '长度不能大于15'}]}]" />
        </a-form-item>
        <a-form-item label="手机号码">
          <a-input v-decorator="['phone', {rules: [{required: true, message: '请输入11位手机号码'}, { pattern: '^[0-9]*$', message: '请输入数字'}, { min: 11, max: 11, message: '请输入11位手机号'}]}]" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'

// 表单字段
const fields = ['name', 'username', 'phone']

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      form: this.$form.createForm(this)
    }
  },
  created () {

    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  }
}
</script>
